import axios from "axios";
import { BASE_URL } from "../configs";

const URL = BASE_URL + "/CategoryArticle";

export const getAllCategoryArticle = () => {
  return axios.get(`${URL}`);
};

export const postCategoryArticle = (data) => {
  return axios.post(`${URL}`, data);
};

export const updateCategoryArticle = (data) => {
  const dataUpdate = {
    name_categorie_article: data.name_categorie_article,
  };
  return axios.put(`${URL}/${data.id_categorie_article}`, dataUpdate);
};

export const deleteCategoryArticle = (id) => {
  return axios.delete(`${URL}/${id}`);
};
